@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Phage Rough";
  font-style: normal;
  src: url('../fonts/PhageRough.otf')
}

@font-face {
  font-family: "Phage Regular";
  font-style: normal;
  src: url('../fonts/PhageRegular.otf')
}

@font-face {
  font-family: "Nidus Sans Regular";
  font-style: normal;
  src: url('../fonts/NidusSansRegular.ttf')
}

.font-phage__rough {
  font-family: "Phage Rough";
}

.font-phage__regular {
  font-family: "Phage Regular";
}

.font-nidus_sans__reguar {
  font-family: "Nidus Sans Regular";
}

.home-page {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-image: url('../images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-shadow {
  filter: drop-shadow(0 5px 25px var(--secondary-text-color));
}

:root {
  --background-color: #292871; 
  --text-color: #ffffff; 
  --border-color: #6cdaef; 
  --accent-background: #eb5d4f; 
  --accent-color: #f976ef; 
  --accent-color-hover: #237ac5; 
  --secondary-text-color: #43c8e2; 
  --onboard-modal-background: var(--background-color);
  --onboard-connect-sidebar-background: var(--accent-background);
  --onboard-close-button-background: var(--accent-background);
  --onboard-connect-sidebar-color: var(--text-color);
  --onboard-connect-sidebar-progress-background: var(--secondary-text-color);
  --onboard-connect-sidebar-progress-color: var(--accent-color);
  --onboard-connect-header-background: var(--background-color);
  --onboard-connect-header-color: var(--text-color);
  --onboard-main-scroll-container-background: var(--background-color);
  --onboard-link-color: var(--accent-color);
  --onboard-wallet-button-background: var(--background-color);
  --onboard-wallet-button-background-hover: var(--accent-color-hover);
  --onboard-wallet-button-border-color: var(--border-color);
  --onboard-wallet-app-icon-border-color: var(--border-color);
  --onboard-wallet-button-color: var(--text-color);
  --onboard-wallet-button-color-hover: var(--text-color);
  --onboard-gray-700: var(--text-color);
  --onboard-modal-z-index: 9999;
  --onboard-wallet-columns: 1;
}